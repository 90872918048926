exports.components = {
  "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-tag-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.tag.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-tag-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-post-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-posts-js" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/404.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-apps-jsx": () => import("./../../../src/pages/apps.jsx" /* webpackChunkName: "component---src-pages-apps-jsx" */),
  "component---src-pages-automatic-time-tracking-in-horeca-jsx": () => import("./../../../src/pages/automatic-time-tracking-in-horeca.jsx" /* webpackChunkName: "component---src-pages-automatic-time-tracking-in-horeca-jsx" */),
  "component---src-pages-biometriya-v-uchete-rabochego-vremeni-i-skud-jsx": () => import("./../../../src/pages/biometriya-v-uchete-rabochego-vremeni-i-skud.jsx" /* webpackChunkName: "component---src-pages-biometriya-v-uchete-rabochego-vremeni-i-skud-jsx" */),
  "component---src-pages-clients-jsx": () => import("./../../../src/pages/clients.jsx" /* webpackChunkName: "component---src-pages-clients-jsx" */),
  "component---src-pages-construction-safety-with-alcohol-testing-jsx": () => import("./../../../src/pages/construction-safety-with-alcohol-testing.jsx" /* webpackChunkName: "component---src-pages-construction-safety-with-alcohol-testing-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-equipment-jsx": () => import("./../../../src/pages/equipment.jsx" /* webpackChunkName: "component---src-pages-equipment-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kfc-jsx": () => import("./../../../src/pages/kfc.jsx" /* webpackChunkName: "component---src-pages-kfc-jsx" */),
  "component---src-pages-knowledge-base-category-jsx": () => import("./../../../src/pages/knowledge-base-category.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-category-jsx" */),
  "component---src-pages-knowledge-base-jsx": () => import("./../../../src/pages/knowledge-base.jsx" /* webpackChunkName: "component---src-pages-knowledge-base-jsx" */),
  "component---src-pages-manufacturing-jsx": () => import("./../../../src/pages/manufacturing.jsx" /* webpackChunkName: "component---src-pages-manufacturing-jsx" */),
  "component---src-pages-minskpromstroy-time-tracking-jsx": () => import("./../../../src/pages/minskpromstroy-time-tracking.jsx" /* webpackChunkName: "component---src-pages-minskpromstroy-time-tracking-jsx" */),
  "component---src-pages-opportunities-category-jsx": () => import("./../../../src/pages/opportunities-category.jsx" /* webpackChunkName: "component---src-pages-opportunities-category-jsx" */),
  "component---src-pages-opportunities-jsx": () => import("./../../../src/pages/opportunities.jsx" /* webpackChunkName: "component---src-pages-opportunities-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-product-tour-jsx": () => import("./../../../src/pages/product-tour.jsx" /* webpackChunkName: "component---src-pages-product-tour-jsx" */),
  "component---src-pages-real-time-construction-insights-jsx": () => import("./../../../src/pages/real-time-construction-insights.jsx" /* webpackChunkName: "component---src-pages-real-time-construction-insights-jsx" */),
  "component---src-pages-targcontrol-vs-goodt-jsx": () => import("./../../../src/pages/targcontrol-vs-goodt.jsx" /* webpackChunkName: "component---src-pages-targcontrol-vs-goodt-jsx" */),
  "component---src-pages-targcontrol-vs-naumen-jsx": () => import("./../../../src/pages/targcontrol-vs-naumen.jsx" /* webpackChunkName: "component---src-pages-targcontrol-vs-naumen-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-time-tracking-construction-jsx": () => import("./../../../src/pages/time-tracking-construction.jsx" /* webpackChunkName: "component---src-pages-time-tracking-construction-jsx" */),
  "component---src-pages-time-tracking-jsx": () => import("./../../../src/pages/time-tracking.jsx" /* webpackChunkName: "component---src-pages-time-tracking-jsx" */),
  "component---src-pages-wfm-forecasting-staff-in-retail-jsx": () => import("./../../../src/pages/wfm-forecasting-staff-in-retail.jsx" /* webpackChunkName: "component---src-pages-wfm-forecasting-staff-in-retail-jsx" */),
  "component---src-pages-wfm-horeca-jsx": () => import("./../../../src/pages/wfm-horeca.jsx" /* webpackChunkName: "component---src-pages-wfm-horeca-jsx" */),
  "component---src-pages-wfm-jsx": () => import("./../../../src/pages/wfm.jsx" /* webpackChunkName: "component---src-pages-wfm-jsx" */),
  "component---src-pages-wfm-planning-restaurant-staff-and-shift-schedule-jsx": () => import("./../../../src/pages/wfm-planning-restaurant-staff-and-shift-schedule.jsx" /* webpackChunkName: "component---src-pages-wfm-planning-restaurant-staff-and-shift-schedule-jsx" */),
  "component---src-pages-wfm-retail-jsx": () => import("./../../../src/pages/wfm-retail.jsx" /* webpackChunkName: "component---src-pages-wfm-retail-jsx" */),
  "component---src-pages-wfm-scheduling-app-for-retail-jsx": () => import("./../../../src/pages/wfm-scheduling-app-for-retail.jsx" /* webpackChunkName: "component---src-pages-wfm-scheduling-app-for-retail-jsx" */),
  "component---src-pages-wfm-scheduling-in-retail-jsx": () => import("./../../../src/pages/wfm-scheduling-in-retail.jsx" /* webpackChunkName: "component---src-pages-wfm-scheduling-in-retail-jsx" */),
  "component---src-pages-wfm-system-for-managers-jsx": () => import("./../../../src/pages/wfm-system-for-managers.jsx" /* webpackChunkName: "component---src-pages-wfm-system-for-managers-jsx" */),
  "component---src-pages-work-time-tracking-in-construction-jsx": () => import("./../../../src/pages/work-time-tracking-in-construction.jsx" /* webpackChunkName: "component---src-pages-work-time-tracking-in-construction-jsx" */)
}

